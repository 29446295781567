import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Legal from "./Pages/Legal";
import NotFound from "./Pages/NotFound";
import Appointment from "./Pages/Appointment";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyDZXjCHNVXbNMfBEtCum51HN6t8CVS9k5E",
//   authDomain: "drgupta-s.firebaseapp.com",
//   projectId: "drgupta-s",
//   storageBucket: "drgupta-s.appspot.com",
//   messagingSenderId: "187353837828",
//   appId: "1:187353837828:web:6fffd1e8690da712d7a051",
//   measurementId: "G-9R3K7KD71Y"
// };

function App() {
  // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// console.log(analytics.app.options.projectId);
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
