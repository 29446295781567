export const customerReviews = [
    {
      "name": "Vishal gupta",
      "location": "UP, INDIA",
      "message": "Dr Gupta Homeopathy transformed my healthcare experience! The online consultations were so convenient, and the doctors were knowledgeable and caring."
    },
    {
      "name": "Niranjan Singh",
      "location": "DELHI",
      "message": "Very good recovery from joint pain. Doctor explained and discussed everything very nicely. The medicines are affordable and worked quickly. Must visit for any problem"
    },
    {
      "name": "Ravi Shankar Singh",
      "location": "BANGALORE",
      "message": "Had skin issues akne and blemishes. But the treatment and the medicines worked like miracle. Very quickly I could see the results. Recommended. Please visit."
    },
    {
      "name": "Vakil Vishwakarma",
      "location": "INDIA",
      "message": "GOOD"
    },
    {
      "name": "Vivek Singh",
      "location": "UP",
      "message": "ACHA HAI"
    },
    {
      "name": "menika singh",
      "location": "INDIA",
      "message": "GOOD"
    },
];
